import {
  createRouter,
  createWebHistory,
  RouteRecordRaw,
  RouteLocationNormalized,
  NavigationGuardNext
} from 'vue-router'
import { inject } from 'vue'
import Auth from '@/views/Auth.vue'
import ControlPanel from '@/views/ControlPanel.vue'
import ControlPanelChildRoutes from '@/router/controlPanel'
import AuthChildRoutes from '@/router/auth'
import { PublicClientApplication } from '@azure/msal-browser'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'home',
    redirect: () => ({ name: 'auth.login' })
  },
  {
    path: '/auth',
    name: 'auth',
    component: Auth,
    redirect: () => ({ name: 'auth.login' }),
    children: AuthChildRoutes
  },
  {
    path: '/cp',
    name: 'control-panel',
    component: ControlPanel,
    children: ControlPanelChildRoutes,
    redirect: () => {
      if (window.innerWidth < 768) {
        return { name: 'control-panel.projects.list' };
      }
      return { name: 'map.projects' };
    },
    beforeEnter: async (to: RouteLocationNormalized, from: RouteLocationNormalized, next: NavigationGuardNext) => {
      const msal: PublicClientApplication | undefined = inject('msal')

      if (!msal) {
        console.error('MSAL instance is not available in router');
        next({ name: 'auth.login' });
        return;
      }

      console.info('Initializing MSAL in router');
      await msal.initialize(); // Ensure MSAL is initialized

      const isAuthenticated = !!msal?.getAllAccounts().length;
      if (to.name !== 'auth.login' && !isAuthenticated) next({ name: 'auth.login' });
      else next();
    },
  }
]

const router = createRouter({
  history: createWebHistory(),
  routes,
  scrollBehavior: (to: any, from: any, savedPosition: any) => {
    return !savedPosition ? { top: 0, left: 0 } : savedPosition
  }
})

router.onError(async (error, to) => {
  if (error.message.includes('Failed to fetch dynamically imported module')) {
    try {
      const cacheNames = await caches.keys();
      await Promise.all(cacheNames.map(name => caches.delete(name)));
      window.location.assign(to.fullPath);
    } catch (cacheError) {
      console.error('Error clearing caches:', cacheError);
      window.location.assign(to.fullPath);
    }
  }
});

export default router;
